/* stylelint-disable selector-class-pattern */
@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/spacing';
@use './mixin.module.scss';

.main,
.smallIcon {
  height: 100%;
}

.main :global {
  video {
    background-position: center;
    background-size: cover;
  }

  .plyr--playing .plyr__controls [data-plyr='play'],
  .plyr--playing .plyr__controls [data-plyr='pause'] {
    display: inline-block;
  }

  .plyr {
    height: 100%;
    font-family: v.$font-family-base;
    outline: none;

    &__poster {
      background-size: cover;
    }

    &__volume input[type='range'] {
      /* Hack for firefox markup bug */
      width: calc(100% - 36px);
    }

    &__control--overlaid[data-plyr='play'] {
      @include mixin.play;

      svg {
        left: auto;
        background: url('../images/video-player-play.svg') no-repeat 50% 50%;
        background-size: contain;

        @include mixin.playIcon;

        use {
          display: none;
        }
      }

      &::after {
        display: block;
        margin-top: 4px;
        margin-left: 51px;
        color: colors.$white;
        content: 'Play';
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
      }
    }

    &__video-embed > div {
      /* ovverride vendor/assets/stylesheets/plyr/scss/plyr.scss Vimeo hack */
      padding-bottom: 0;
      transform: 0;
    }
  }
}

.smallIcon :global {
  video {
    background-position: center;
    background-size: cover;
  }

  .plyr--playing .plyr__controls [data-plyr='play'],
  .plyr--playing .plyr__controls [data-plyr='pause'] {
    display: inline-block;
  }

  .plyr {
    height: 100%;
    font-family: v.$font-family-base;
    outline: none;

    &__poster {
      background-size: cover;
    }

    &__volume input[type='range'] {
      /* Hack for firefox markup bug */
      width: calc(100% - 36px);
    }

    &__control--overlaid[data-plyr='play'] {
      @include mixin.play;
      @include mixin.playSmall;

      svg {
        left: auto;
        background: url('../images/video-player-play.svg') no-repeat 50% 50%;
        background-size: contain;

        @include mixin.playIcon;
        @include mixin.playIconSmall;

        use {
          display: none;
        }
      }
    }

    &__video-embed > div {
      /* ovverride vendor/assets/stylesheets/plyr/scss/plyr.scss Vimeo hack */
      padding-bottom: 0;
      transform: 0;
    }
  }

  .plyr:hover,
  .plyr:focus {
    .plyr__control--overlaid[data-plyr='play'] {
      @include mixin.playTransformSmall;
    }
  }
}

.main {
  .plyr:hover,
  .plyr:focus {
    .plyr__control--overlaid[data-plyr='play'] {
      @include mixin.playTransform;
    }
  }
}

@use 'assets/styles/variables';
@use 'assets/styles/colors';
@use 'assets/styles/spacing';
@use 'assets/styles/media';
@use './player/mixin.module.scss';

.aspectRatioWidescreen {
  aspect-ratio: 16 / 9;
}

.aspectRatioStandard {
  aspect-ratio: 4 / 3;
}

.aspectRatioCinematic {
  aspect-ratio: 2.39 / 1;
}

.main {
  position: relative;
  overflow: hidden;
  min-width: 200px;
  max-width: 100%;
}

.plyrWrapper {
  &.hidden {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.play {
  @include mixin.play;

  position: absolute;
  top: 50%;
  left: 50%;

  .playIcon {
    background: url('./images/video-player-play.svg') no-repeat 50% 50%;
    background-size: contain;

    @include mixin.playIcon;
  }

  .playText {
    text-transform: uppercase;
  }
}

.placeholder {
  position: relative;
  z-index: 1;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: colors.$gray-lighter;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}

.smallIcon {
  .play {
    @include mixin.playSmall;
  }

  .playText {
    display: none;
  }

  .playIcon {
    @include mixin.playIconSmall;
  }
}

.placeholder:hover,
.placeholder:focus {
  .play {
    @include mixin.playTransform;
  }
}

.smallIcon .placeholder {
  &:hover,
  &:focus {
    .play {
      @include mixin.playTransformSmall;
    }
  }
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.preview {
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
}

@use 'assets/styles/colors';
@use 'assets/styles/media';

.slider {
  :global {
    .swiper-pagination {
      display: flex;
      justify-content: center;
      margin-top: 36px;

      @include media.up(lg) {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: rgba((colors.$grey-d8), 0.4);

      &:not(:last-child) {
        margin-right: 17px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: rgba((colors.$white), 0.9);

      &:hover {
        cursor: none;
      }
    }
  }
}

.slide {
  max-width: var(--slide-width);

  @media (width >= 375px) {
    max-width: 330px;
  }

  @media (width >= 679px) {
    max-width: 630px;
  }

  @include media.up(md) {
    max-width: 745px;
  }
}

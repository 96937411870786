@use 'sass:map';
@use 'media';
@use 'variables' as v;
@use 'colors';

@mixin propMap($name, $prop, $val, $map) {
  .#{$name} {
    #{$prop}: map.get($map, 'lg') or $val;
  }

  @include media.down(md) {
    .#{$name}-md {
      #{$prop}: map.get($map, 'md') or $val;
    }
  }

  @include media.down(sm) {
    .#{$name}-sm {
      #{$prop}: map.get($map, 'sm') or $val;
    }
  }

  @include media.down(xs) {
    .#{$name}-xs {
      #{$prop}: map.get($map, 'xs') or $val;
    }
  }
}

@mixin prop($name, $prop, $val) {
  @include propMap($name, $prop, $val, ());
}

@mixin create($name, $prop) {
  @include propMap(
    $name,
    $prop,
    '',
    (
      'lg': var(--sp-#{$name}),
      'md': var(--sp-#{$name}-md),
      'sm': var(--sp-#{$name}-sm),
      'xs': var(--sp-#{$name}-xs),
    )
  );
}

@include create(p, padding);
@include create(m, margin);
@include create(h, height);
@include create(w, width);

// Why is this in here? Is this file only for BOX
@each $name,
  $color
    in (
      'gray-f2': colors.$gray-f2,
      'white': colors.$white,
      'light-blue': colors.$brand-light-blue,
      'danger': colors.$danger,
      'black': colors.$black-80,
      'gray-e0': colors.$gray-e0,
      'gray-f7': colors.$gray-f7,
      'gray-78': colors.$gray-78
    )
{
  @include prop(bg-#{$name}, background-color, $color);
}

@each $size in (2, 3, 4, 5, 6, 8, 10, 12, 13, 16, 24) {
  @include prop(r-#{$size}, border-radius, #{$size}px);
}

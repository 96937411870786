@use 'assets/styles/colors';
@use 'assets/styles/media';

@mixin play {
  display: flex;
  width: 140px;
  height: 140px;
  flex-direction: column;
  padding: 0;
  border: 4px solid colors.$white;
  border-radius: 50%;
  background-color: rgba((colors.$black), 0.3);
  outline: none;
  transform: translate(-50%, -50%);
  transition-duration: 0.3s;
  transition-property: transform, background-color;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: rgba((colors.$black), 0.5);
  }

  @include media.down(sm) {
    transform: translate(-50%, -50%) scale(0.56);
  }
}

@mixin playSmall {
  width: 60px;
  height: 60px;

  @include media.down(sm) {
    transform: translate(-50%, -50%);
  }
}

@mixin playTransform {
  background-color: rgba((colors.$black), 0.5);
  transform: translate(-50%, -50%) scale(1.1);

  @include media.down(sm) {
    transform: translate(-50%, -50%) scale(0.61);
  }
}

@mixin playTransformSmall {
  background-color: rgba((colors.$black), 0.5);
  transform: translate(-50%, -50%) scale(1.1);

  @include media.down(sm) {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

@mixin playIcon {
  width: 39px;
  height: 46px;
  margin-top: 37px;
  margin-left: 51px;
  transition: 0.1s ease-in-out;
  transition-property: background-color;
}

@mixin playIconSmall {
  width: 21px;
  height: 18px;
  margin-top: 18px;
  margin-left: 18px;
}

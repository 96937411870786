@use 'assets/styles/colors';

.slide {
  position: relative;
  display: flex;
  max-width: 294px;
  box-sizing: content-box;

  &::after {
    position: absolute;
    top: 0;
    right: -1px;
    display: block;
    width: 1px;
    height: 36px;
    background-color: rgba((colors.$gray-70), 0.2);
    content: '';
  }
}
